import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'
import React, { useEffect } from 'react'

import VoidCallout from 'happitu/src/components/Layout/VoidCallout'
import { getOrganizationId } from 'happitu/src/helpers/authHelpers'
import {
  getParams,
  navigateTo,
  NewTicketRouteParams,
} from 'happitu/src/helpers/routeHelpers'

export const client = createTRPCProxyClient({
  links: [
    httpBatchLink({
      url: process.env.SERVICE_API_V2 as string,
      methodOverride: 'POST',
      headers: () => {
        return {
          'happitu-organization-id': getOrganizationId() as string,
          // @ts-ignore
          'authorization': `Bearer ${global.HAPPITU_AUTH_TOKEN}`,
        }
      },
    }),
  ],
})

type Route = NewTicketRouteParams & {
  lookupKey?: ID
  lookupValue?: string
}

const FindOrCreateTicket = () => {
  useEffect(() => {
    const { lookupKey, lookupValue, ...params } = getParams<Route>()
    if (!lookupKey && !lookupValue) navigateTo('app.tickets.new', params as any)
    // @ts-ignore
    client.legacy.lookupTicket
      .query({
        workflowId: params.workflowId,
        key: lookupKey,
        id: lookupValue,
      })
      .then((result: TicketInteractionRecord | undefined) => {
        if (result) {
          navigateTo('app.tickets.work', {
            ticketId: result.ticketId,
            ticketInteractionId: result.id,
            stepIndex: `${result.workflowSteps.length - 1}`,
          })
        } else {
          navigateTo('app.tickets.new', {
            ...params,
            [`var${lookupKey}`]: lookupValue,
            lookupId: lookupValue,
            lookupKey: lookupKey,
          })
        }
      })
  }, [])

  return <VoidCallout>Initializing ticket...</VoidCallout>
}

export default FindOrCreateTicket
