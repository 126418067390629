import React, { useState, useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import HelpTopicListItem from '../../ticketing/components/HelpTopics/HelpTopicListItem'

import SearchInput from 'happitu/src/components/Fields/SearchInput'
import Icon from 'happitu/src/components/Icon'
import Scrollable from 'happitu/src/components/Layout/Scrollable'
import { SectionHeading } from 'happitu/src/components/Section'
import { Button } from 'happitu/src/components/button'
import getStyle from 'happitu/src/getStyle'
import { error } from 'happitu/src/helpers/loggerHelper'
import { pluralize } from 'happitu/src/helpers/stringHelper'
import useSuggester from 'happitu/src/hooks/useSuggester'
import { setRecentHelpTopics } from 'happitu/src/modules/help-topic-list/help-topic-list.helpers'
import { connectTicketContext, TicketComponentProps } from 'happitu/src/modules/ticketing'
import TicketingHelpTopicsContext from 'happitu/src/modules/ticketing/ticketing-help-topics.context'
import { searchHelpTopicsRequest } from 'happitu/src/services/happitu-api/helpTopicService'
import { HelpTopicRecord } from 'happitu/src/types/models/helpTopics'

type Props = TicketComponentProps<
  | 'getHelpTopic'
  | 'helpTopicPages'
  | 'helpTopics'
  | 'locations'
  | 'suggestedHelpTopics'
  | 'tags'
  | 'tickets'
  | 'users'
> &
  ExternalProps

function TicketingSearch(props: Props) {
  const { advanceScreen, setStep, screenHistory, setScreenHistory } = useContext(
    TicketingHelpTopicsContext,
  )
  const [helpTopicIds, setHelpTopicIds] = useState<ID[]>([])
  const [isSearching, setIsSearching] = useState(false)
  const [loading, setLoading] = useState(false)

  const [query, _, __, setQuery] = useSuggester(async (q) => {
    if (props.workflowId && Number.isInteger(props.workflowVersion) && q !== '') {
      setLoading(true)
      try {
        const newHelpTopicIds = (await searchHelpTopicsRequest(q, [], {
          limit: 1000,
          responseScope: 'ids',
          workflowIds: [`${props.workflowId}${props.workflowVersion}`],
        })) as ID[]
        setHelpTopicIds(newHelpTopicIds)
      } finally {
        setLoading(false)
      }
    }
  })

  const handleNavigate = (helpTopicId: ID) => {
    setIsSearching(false)
    const helpTopic = props.helpTopics.findById<HelpTopicRecord>(helpTopicId)
    if (!helpTopic)
      throw new Error('HelpTopics: error navigating to help topic. missing help topic')

    advanceScreen(helpTopic.impressionId, helpTopic.pageIds[0])
    setRecentHelpTopics(props.workflowId, helpTopic.impressionId)
  }

  useEffect(() => {
    if (query) setIsSearching(true)
    else setIsSearching(false)
  }, [query])

  return (
    <>
      <Wrapper>
        <Header>
          <StyledSearchInput
            placeholder="Search help topics"
            loading={loading}
            onChange={setQuery}
            // onBlur={() => setTimeout(() => setIsSearching(false), 200)}
            value={query}
          />
          <Nav>
            <NavButton
              title="Documentation"
              ghost
              active={props.view === 'documentation'}
              onClick={() => props.onSelectTab('documentation')}
            >
              <Icon type="documentation" />
            </NavButton>
            <NavButton
              title="Workflow"
              ghost
              active={props.view === 'workflow'}
              onClick={() => props.onSelectTab('workflow')}
            >
              <Icon type="workflow" />
            </NavButton>
            <NavButton
              title="Help Topics"
              ghost
              active={props.view === 'help-topics'}
              onClick={() => {
                setStep(0)
                setScreenHistory([screenHistory[0]])
                props.onSelectTab('help-topics')
              }}
            >
              <Icon type="graduation-cap" />
            </NavButton>
          </Nav>
        </Header>
        {!!isSearching && (
          <Scrollable>
            <SectionHeading
              title={`Found ${helpTopicIds?.length} ${pluralize('Help Topic', helpTopicIds?.length)}`}
              isVisible
            />
            <div style={{ marginTop: '.5em' }}>
              {helpTopicIds.map((id) => {
                const helpTopic = props.helpTopics.findById<HelpTopicRecord>(id)
                if (!helpTopic) {
                  error(
                    `HelpTopicSearchResults: missing help topic in search results ${id}`,
                  )
                  return null
                }
                return (
                  <HelpTopicListItem
                    key={id}
                    helpTopic={helpTopic}
                    helpTopicPages={props.helpTopicPages}
                    onNavigate={handleNavigate}
                    showPreview
                  />
                )
              })}
            </div>
          </Scrollable>
        )}
      </Wrapper>
      {!!isSearching && <Backdrop />}
    </>
  )
}

type View = 'workflow' | 'documentation' | 'help-topics'
interface ExternalProps {
  workflowId: ID
  workflowVersion: number
  onSelectTab: (view: View) => void
  view: View
}

const Backdrop = styled.div`
  position: fixed;
  top: 5em;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25em;
`

const StyledSearchInput = styled(SearchInput)`
  flex-grow: 1;
`

const NavButton = styled(Button)<{ active: boolean }>`
  border-radius: 0;
  ${ifProp(
    'active',
    css`
      border-bottom: 3px solid ${getStyle('button-primary-active-background')};
    `,
  )}
`

const Wrapper = styled.header`
  background: ${getStyle('app-background')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1em;
  z-index: 10;
`

export const Nav = styled.nav`
  display: flex;
  shrink: 0;
  width: fit-content;
  align-items-center;
  justify-self: start;
  border-radius: .25em;
  padding: 0.25em;

`

export default connectTicketContext<ExternalProps>(
  ['helpTopics', 'tags', 'suggestedHelpTopics', 'helpTopicPages'],
  ['getHelpTopic'],
)(TicketingSearch)
