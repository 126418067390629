import update from 'immutability-helper'
import { get, last } from 'lodash'

import { convertLabelToVariable, stringifyFieldValue } from '../helpers/variableHelper'

import relay, { relayError, RelayDispatch, UnpackService } from './relay'

import { newTicketingStep, TicketStatus } from 'happitu/src/constants/ticketConstants'
import { error } from 'happitu/src/helpers/loggerHelper'
import { getParams, WorkTicketRouteParams } from 'happitu/src/helpers/routeHelpers'
import {
  updateContact,
  finalizeContact,
} from 'happitu/src/helpers/ticket/setContactAttribute'
import {
  getCurrentStepIndex,
  getCurrentStepValues,
  getCurrentTicket,
  getInteractionFromRoute,
} from 'happitu/src/helpers/ticket/ticketAttributeFinders'
import validateWorkflowStep, {
  validateField,
} from 'happitu/src/helpers/ticket/validateWorkflowStep'
import { parseParamData } from 'happitu/src/modules/ticketing/helpers/parse-param-data'
import { getContactRequest } from 'happitu/src/services/happitu-api/contactsService'
import { uploadFileRequest } from 'happitu/src/services/happitu-api/fileUploadService'
import { embedRecordEvent } from 'happitu/src/services/happitu-api/recordEventService'
import {
  createInteractionRequest,
  updateInteractionRequest,
} from 'happitu/src/services/happitu-api/ticketInteractionsService'
import {
  getTicketRequest,
  updateTicketRequest,
} from 'happitu/src/services/happitu-api/ticketsService'
import { fetchWorkflowRequest } from 'happitu/src/services/happitu-api/workflowsService'
import { TicketState, TicketValueHistory } from 'happitu/src/types/ticketContext'

const FRACTURED_STATE_MESSAGE =
  '💧 A hydrated workflow is required before we can modify an interaction.'

type InteractionRelayAction = UnpackService<typeof fetchWorkflowRequest> &
  UnpackService<typeof updateInteractionRequest> & {
    currentContact: Partial<ContactRecord>
    fileUploads: FileUploadRecord[]
    valueHistory: TicketValueHistory
  }

type InteractionRelayAddedState = Partial<
  Omit<InteractionRelayAction, 'ticketInteractions'>
>

export function createInteraction(ticketId: ID, workflowId: ID) {
  return async (dispatch: RelayDispatch<typeof createInteractionRequest>) => {
    const response = await createInteractionRequest(ticketId, workflowId)
    relay(dispatch)(response)
    return response
  }
}

function getStepRecord(workflowSteps: WorkflowStepStore, stepId: ID) {
  const nextStep = workflowSteps.findById<WorkflowStepRecord>(stepId)
  if (!nextStep) {
    throw new Error(`getStepRecord: ${stepId} is not preset in the workflow payload.`)
  }
  return nextStep
}

function initializeContactRecordFromInteraction(
  interaction: TicketInteractionRecord,
  workflowElements: WorkflowElementStore,
): Partial<ContactRecord> {
  return {
    firstName: interaction.variableOverwrites?.contact_first_name,
    lastName: interaction.variableOverwrites?.contact_last_name,
    phones: interaction.variableOverwrites?.contact_phone
      ? [{ value: interaction.variableOverwrites.contact_phone, type: 'home' }]
      : [],
    ...interaction.workflowSteps.reduce((contact, step) => {
      step.fields.forEach((field) => {
        const element = workflowElements.findById<WorkflowElementRecord>(field.elementId)
        return updateContact(field.values, contact, element)
      })
      return contact
    }, {}),
  }
}

export const initializeInteractionMetaData = (
  interaction: TicketInteractionRecord,
  params: Record<string, any> = {},
) => {
  return async (dispatch: RelayDispatch<InteractionRelayAction>) => {
    const parsedParams = parseParamData(params)
    // Only update the interaction if there's data to parse
    if (
      Object.keys(parsedParams.variableOverwrites).length ||
      Object.keys(parsedParams.externalData).length
    )
      try {
        const updatedInteraction: TicketInteractionRecord = {
          ...interaction,
          externalData: {
            ...interaction.externalData,
            ...parsedParams.externalData,
          },
          variableOverwrites: {
            ...interaction.variableOverwrites,
            ...parsedParams.variableOverwrites,
          },
        }
        const response = await updateInteractionRequest(updatedInteraction)
        relay(dispatch)(response)
        return response.ticketInteractions[0]
      } catch (e) {
        error('updateInteractionMeta', e)
      }
    return interaction
  }
}

export function initializeInteraction(
  preProcessedInteraction: TicketInteractionRecord,
  currentContact?: Partial<ContactRecord> | null,
  variables?: Record<string, any>,
) {
  return async (
    dispatch: RelayDispatch<InteractionRelayAction>,
    getState: () => TicketState,
  ) => {
    try {
      const interaction = await initializeInteractionMetaData(
        preProcessedInteraction,
        variables,
      )(dispatch)

      const currentStepIndex = getCurrentStepIndex(interaction.workflowSteps)
      const currentStepValues = getCurrentStepValues(interaction.workflowSteps)
      const needsInit = !currentStepValues || currentStepValues.fields.length === 0
      const workflowResponse = await fetchWorkflowRequest(
        interaction.workflowId,
        interaction.workflowVersion,
      )

      relay(dispatch)(workflowResponse)
      const { workflowElements, workflowStepGroupSteps } = getState()
      if (!currentContact && workflowElements) {
        currentContact = initializeContactRecordFromInteraction(
          interaction,
          workflowElements,
        )
      }

      if (!currentContact) {
        currentContact = {}
      }

      if (needsInit) {
        const stepRecord = getStepRecord(workflowStepGroupSteps, currentStepValues.stepId)
        const { variables } = getState()
        const nextInteraction = buildInteractionStep(interaction, currentStepIndex, {
          stepRecord,
          valueHistory: {},
          variables,
          workflowElements,
          contact: currentContact,
        })
        const valueHistory = initializeValueHistory(nextInteraction.workflowSteps)
        dispatchInteraction(nextInteraction, {
          currentContact,
          valueHistory,
        })(dispatch)
      } else {
        const valueHistory = initializeValueHistory(interaction.workflowSteps)
        dispatchInteraction(interaction, {
          currentContact,
          valueHistory,
        })(dispatch)
      }
    } catch (e) {
      error('initializeInteraction', e)
    }
  }
}

function initializeValueHistory(workflowSteps: InteractionStep[]): TicketValueHistory {
  return workflowSteps.reduce<TicketValueHistory>((acc, step, index) => {
    acc[index] = step.fields.reduce<Record<string, string[]>>((values, field) => {
      values[field.elementId] = field.values
      return values
    }, {})
    return acc
  }, {})
}

function updateCurrentStep(
  change: object,
  ticketInteraction?: TicketInteractionRecord,
  stepIndex?: string,
  variableOverwritesChange?: object,
) {
  return (getState: () => TicketState) => {
    const interaction =
      ticketInteraction || getInteractionFromRoute(getState().ticketInteractions)

    const currentStepIndex = stepIndex || getCurrentStepIndex(interaction.workflowSteps)
    return update(interaction, {
      variableOverwrites: variableOverwritesChange || {},
      workflowSteps: { [currentStepIndex]: change },
    })
  }
}

function getFieldVal(elementId: ID, getState: () => TicketState, stepIndex?: string) {
  const interaction = getInteractionFromRoute(getState().ticketInteractions)
  const currentStepValues = getCurrentStepValues(interaction.workflowSteps, stepIndex)

  if (!currentStepValues) {
    throw new Error('Missing current step in local state.')
  }

  const fieldIndex = getFieldIndex(elementId, currentStepValues.fields)
  const field = currentStepValues.fields[fieldIndex]
  const element = getState().workflowElements.findById<WorkflowElementRecord>(elementId)

  return {
    interaction,
    fieldIndex,
    field,
    element,
  }
}

function updateFieldAttributeInInteraction(
  key: string,
  values: string[],
  elementId: ID,
  getState: () => TicketState,
  stepIndex?: string,
) {
  const { fieldIndex, field, element } = getFieldVal(elementId, getState, stepIndex)

  window.parent.postMessage(
    {
      type: 'ON_VALUE_CHANGE',
      payload: {
        name: element?.attributes?.label,
        values: values,
        id: elementId,
      },
    },
    '*',
  )

  return updateCurrentStep(
    {
      fields: {
        $splice: [
          [
            fieldIndex,
            1,
            {
              ...field,
              _error: validateField(values, element),
              elementId,
              [key]: values,
            },
          ],
        ],
      },
    },
    undefined,
    stepIndex,
    {
      [convertLabelToVariable(field.name)]: {
        $set: stringifyFieldValue({ ...field, [key]: values }),
      },
    },
  )(getState)
}

function compileValueHistory(
  workflowSteps: InteractionStep[],
  elementId: ID,
  values: string[],
  getState: () => TicketState,
): TicketValueHistory {
  const valueHistory = getState().valueHistory || {}
  const currentStepIndex = getCurrentStepIndex(workflowSteps)
  const changes = valueHistory[currentStepIndex]
    ? {
        [currentStepIndex]: {
          [elementId]: { $set: values },
        },
      }
    : {
        [currentStepIndex]: {
          $set: { [elementId]: values },
        },
      }
  return update(valueHistory, changes)
}

export function updateValues(
  values: string[],
  elementId: ID,
  addState: InteractionRelayAddedState = {},
) {
  return (
    dispatch: RelayDispatch<InteractionRelayAction>,
    getState: () => TicketState,
  ) => {
    const element = getState().workflowElements.findById<WorkflowElementRecord>(elementId)
    const contactChanges = updateContact(values, getState().currentContact, element)
    const trimmedValues = values.map((v) => (!!v ? v.trim() : v))
    const updatedInteraction = updateFieldAttributeInInteraction(
      'values',
      trimmedValues,
      elementId,
      getState,
    )
    const valueHistory = compileValueHistory(
      updatedInteraction.workflowSteps,
      elementId,
      values,
      getState,
    )
    return dispatchInteraction(updatedInteraction, {
      ...addState,
      currentContact: contactChanges,
      valueHistory,
    })(dispatch)
  }
}

export function updateFileIds(elementId: ID, fileIds: ID[]) {
  return async (dispatch: React.Dispatch<any>, getState: () => TicketState) => {
    const updatedInteraction = updateFieldAttributeInInteraction(
      'fileIds',
      fileIds,
      elementId,
      getState,
    )
    return dispatchInteraction(updatedInteraction)(dispatch)
  }
}

// TODO: Handle cancel mid-request for uploadFileRequest to prevent uploads from completing.
export function uploadFile(
  elementId: ID,
  files: File[],
  progressCallback: (progress: number) => void,
  cancelled = () => false,
) {
  // eslint-disable-next-line complexity
  return async (
    dispatch: RelayDispatch<InteractionRelayAction>,
    getState: () => TicketState,
  ) => {
    if (cancelled()) return // Check if upload is initially canceled.
    const { stepIndex } = getParams<WorkTicketRouteParams>()
    const { interaction, field } = getFieldVal(elementId, getState)

    const newFileIds = (field.fileIds || []) as ID[]
    const fileUploads = [] as FileUploadRecord[]

    for (let i = 0; i < files.length; i++) {
      if (cancelled()) break // Break out of multiple uploads if canceled.
      const file = files[i]
      const response = await uploadFileRequest(
        'ticketInteractions',
        interaction.id,
        file,
        (p) => {
          progressCallback((p * (i + 1)) / files.length)
        },
      )
      if (cancelled()) break // Cancel before setting fileIds.
      response.fileUploads.forEach((f: FileUploadRecord) => {
        newFileIds.push(f.id)
        fileUploads.push(f)
      })
    }

    const updatedInteraction = updateFieldAttributeInInteraction(
      'fileIds',
      newFileIds,
      elementId,
      getState,
      stepIndex,
    )

    return dispatchInteraction(updatedInteraction, { fileUploads })(dispatch)
  }
}

export function updateNotes(value: string) {
  return (
    dispatch: RelayDispatch<InteractionRelayAction>,
    getState: () => TicketState,
  ) => {
    const interaction = getInteractionFromRoute(getState().ticketInteractions)
    return dispatchInteraction({ ...interaction, notes: value })(dispatch)
  }
}

function resetHistory(recordEvent?: Partial<RecordEvent>) {
  if (recordEvent && recordEvent.eventType === 'resetInteraction') {
    return {
      reset: { valueHistory: true },
      valueHistory: {},
    }
  }
  return {}
}

type ChangeCallback = (
  interaction: TicketInteractionRecord,
) => Promise<TicketInteractionRecord> | TicketInteractionRecord

function updateInteraction(
  changeCallback: ChangeCallback,
  recordEvent?: Partial<RecordEvent>,
  bypassValidation?: boolean,
) {
  return async (
    dispatch: RelayDispatch<InteractionRelayAction>,
    getState: () => TicketState,
  ) => {
    const interaction = getInteractionFromRoute(getState().ticketInteractions)
    const currentStepValues = getCurrentStepValues(interaction.workflowSteps)
    const { workflowElements, workflowStepGroupSteps } = getState()
    let nextInteraction

    if (!workflowStepGroupSteps || !currentStepValues) {
      throw new Error(FRACTURED_STATE_MESSAGE)
    }

    const { isValid, fields, validationAttempts } = validateWorkflowStep(
      currentStepValues.fields,
      workflowElements,
      currentStepValues.validationAttempts,
    )

    nextInteraction = updateCurrentStep({
      fields: { $set: fields },
      validationAttempts: { $set: validationAttempts },
    })(getState)

    // Fix yo' fields, broh.
    if (isValid || bypassValidation) {
      nextInteraction = await changeCallback(nextInteraction)
      nextInteraction = buildTags(nextInteraction)(getState)
    }

    dispatchInteraction(nextInteraction, resetHistory(recordEvent))(dispatch)

    if (isValid || bypassValidation) {
      try {
        const { ticketInteractions, ...relayResponse } = await updateInteractionRequest(
          embedRecordEvent(nextInteraction, recordEvent),
        )
        dispatchInteraction(ticketInteractions[0], relayResponse)(dispatch)
        return ticketInteractions[0]
      } catch (e) {
        if (Array.isArray(e)) {
          relayError(dispatch)(e)
        } else {
          error('unknown error when advancing interaction', e)
        }
      }
    }
    return
  }
}

export function linkContact(
  contactId: ID,
  autoMatch?: TicketRecord['contactAutoMatched'],
) {
  return async (
    dispatch: RelayDispatch<InteractionRelayAction>,
    getState: () => TicketState,
  ) => {
    const { ticketId } = getParams<WorkTicketRouteParams>()

    const response = await getContactRequest(contactId)
    const ticketResponse = await updateTicketRequest(ticketId, {
      contactId,
      contactAutoMatched: autoMatch,
    })

    const {
      variables,
      valueHistory,
      workflowElements,
      workflowStepGroupSteps,
      ticketInteractions,
    } = getState()

    const interaction = getInteractionFromRoute(ticketInteractions)
    const currentStepIndex = getCurrentStepIndex(interaction.workflowSteps)
    const stepRecord = getStepRecord(
      workflowStepGroupSteps,
      interaction.workflowSteps[currentStepIndex].stepId,
    )

    relay(dispatch)({
      currentContact: response.contacts[0],
      ...response,
      ...ticketResponse,
      ticketInteractions: [
        {
          ...buildInteractionStep(interaction, currentStepIndex, {
            stepRecord,
            variables,
            valueHistory,
            workflowElements,
            contact: response.contacts[0],
          }),
          contactId,
        },
      ],
    })
  }
}

function setActionPrefix(action: WorkflowActionRecord, step?: WorkflowStepRecord) {
  // Prevent undefined value from being set in documentation.
  const actionDocPrefix = get(step, 'actionDocPrefix', '')
  return action.documentation
    ? action.documentation
    : `${actionDocPrefix}${actionDocPrefix ? ' ' : ''}${action.name}`
}

export function advanceInteraction(action: WorkflowActionRecord) {
  return async (
    dispatch: RelayDispatch<InteractionRelayAction>,
    getState: () => TicketState,
  ) => {
    if (action.escalationWorkflowId && !action.targetStepId) {
      const validatedInteraction = await updateInteraction(
        (interaction) => {
          const { workflowStepGroupSteps } = getState()

          const currentStepIndex = getCurrentStepIndex(interaction.workflowSteps)
          const currentStepValues = interaction.workflowSteps[currentStepIndex]
          const currentStepRecord = workflowStepGroupSteps.findById<WorkflowStepRecord>(
            currentStepValues.stepId,
          )

          const nextInteraction = updateCurrentStep(
            {
              action: {
                $set: {
                  actionId: action.id,
                  display: setActionPrefix(action, currentStepRecord),
                  documentation: setActionPrefix(action, currentStepRecord),
                },
              },
            },
            interaction,
          )(getState)

          const finalInteraction = setFinalizeAttributes(
            nextInteraction,
            TicketStatus.Closed,
            {
              nextWorkflowId: action.escalationWorkflowId,
            },
          )
          return finalInteraction
        },
        { eventType: 'finalizeInteraction', value: { status } },
      )(dispatch, getState)

      if (!validatedInteraction) throw new Error('failed to finalize interaction')

      const response = await getTicketRequest(validatedInteraction.ticketId)
      const newInteraction = last(response.ticketInteractions)

      relay(dispatch)(response)

      return {
        validatedInteraction: newInteraction,
      }
    } else {
      const validatedInteraction = await updateInteraction((interaction) => {
        const {
          currentContact,
          workflowStepGroupSteps,
          workflowElements,
          valueHistory,
          variables,
        } = getState()

        const currentStepIndex = getCurrentStepIndex(interaction.workflowSteps)
        const currentStepValues = interaction.workflowSteps[currentStepIndex]
        const currentStepRecord = workflowStepGroupSteps.findById<WorkflowStepRecord>(
          currentStepValues.stepId,
        )

        const nextStepRecord = getStepRecord(workflowStepGroupSteps, action.targetStepId)
        const nextInteraction = updateCurrentStep(
          {
            action: {
              $set: {
                actionId: action.id,
                display: setActionPrefix(action, currentStepRecord),
                documentation: setActionPrefix(action, currentStepRecord),
              },
            },
          },
          interaction,
        )(getState)
        return buildInteractionStep(nextInteraction, currentStepIndex + 1, {
          stepRecord: nextStepRecord,
          variables,
          valueHistory,
          workflowElements,
          contact: currentContact,
        })
      })(dispatch, getState)

      return {
        validatedInteraction: validatedInteraction,
      }
    }
  }
}

export function finalizeInteraction(status = 0, callback?: () => void) {
  return async (
    dispatch: RelayDispatch<InteractionRelayAction>,
    getState: () => TicketState,
  ) => {
    const currentTicket = getCurrentTicket(getState().tickets)
    const bypassValidation = !(currentTicket && currentTicket.reportable) || status !== 0
    const validatedInteraction = await updateInteraction(
      async (interaction) => {
        const lastStep = interaction.workflowSteps[interaction.workflowSteps.length - 1]
        const { currentContact, workflowStepGroupSteps } = getState()
        const step = workflowStepGroupSteps.findById<WorkflowStepRecord>(lastStep.stepId)

        const response = await finalizeContact(currentContact, interaction.ticketId)
        const contactId =
          response && response.contacts ? response.contacts[0].id : undefined
        const nextInteraction = { ...interaction } as CreateInteractionRecord
        if (contactId) {
          nextInteraction.contactId = contactId
        }
        return setFinalizeAttributes(nextInteraction, status, step)
      },
      { eventType: 'finalizeInteraction', value: { status } },
      bypassValidation,
    )(dispatch, getState)
    if (!validatedInteraction) return

    if (callback) callback()
  }
}

/**
 * Private methods
 */
function dispatchInteraction(
  interaction: TicketInteractionRecord,
  addState: InteractionRelayAddedState = {},
) {
  return (dispatch: RelayDispatch<InteractionRelayAction>) => {
    relay(dispatch)({ ticketInteractions: [interaction], ...addState })
  }
}

type CreateInteractionRecord = TicketInteractionRecord & { contactId?: ID }

function setFinalizeAttributes(
  interaction: CreateInteractionRecord,
  status: number,
  lastStep?: Partial<WorkflowStepRecord>,
) {
  const userId = getCurrentUser().id
  return update(interaction, {
    createdBy: {
      $set: interaction.createdBy === null ? userId : interaction.createdBy,
    },
    finalizedAt: { $set: status === 1 ? null : undefined },
    nextWorkflowId: {
      $set:
        lastStep && lastStep.nextWorkflowId && status === 0
          ? lastStep.nextWorkflowId
          : interaction.workflowId,
    },
    status: { $set: status },
    updatedBy: { $set: userId },
    userId: { $set: userId },
    workedBy: { $set: userId },
  })
}

function getCurrentUser() {
  return window.store.getState().user
}

function getFieldIndex(elementId: ID, fields: InteractionStepField[]) {
  const index = fields.findIndex((f) => f.elementId === elementId)
  return index === -1 ? fields.length : index
}

interface InteractionStepCollections {
  stepRecord: WorkflowStepRecord
  valueHistory: Record<string, Record<string, string[]>>
  variables: Record<string, string>
  workflowElements: WorkflowElementStore
  contact: Partial<ContactRecord>
}

function buildInteractionStep(
  interaction: TicketInteractionRecord,
  stepIndex: number,
  recordReferences: InteractionStepCollections,
) {
  const { contact, stepRecord, valueHistory, variables, workflowElements } =
    recordReferences
  const nextStep = newTicketingStep(
    stepRecord,
    workflowElements,
    contact,
    valueHistory[stepIndex],
    variables,
  )
  const changes = [[stepIndex, 1, nextStep]]
  // Remove additional steps if the user goes down a different workflow path.
  if (stepIndex + 2 < interaction.workflowSteps.length) {
    changes.push([stepIndex + 1, interaction.workflowSteps.length - stepIndex])
  }
  return update(interaction, {
    workflowSteps: { $splice: changes },
  })
}

function buildTags(interaction: TicketInteractionRecord) {
  return (getState: () => TicketState) => {
    const { workflowActions } = getState()
    if (!workflowActions) return interaction

    const originalTags = interaction.workflowSteps.reduce((tags, step) => {
      const action = workflowActions.findById<WorkflowActionRecord>(
        get(step, 'action.actionId'),
      )
      if (!action || !action.tagId) return tags
      return update(tags, {
        $push: [
          {
            stepId: step.stepId,
            tagId: action.tagId,
          },
        ],
      })
    }, [])

    return update(interaction, {
      originalTags: { $set: originalTags },
      tags: {
        $set: [...get(interaction, 'appliedTags', []), ...originalTags],
      },
    })
  }
}

export function resetInteraction(interaction: TicketInteractionRecord) {
  return async (
    dispatch: RelayDispatch<InteractionRelayAction>,
    getState: () => TicketState,
  ) => {
    const { currentContact, workflowElements, workflowStepGroupSteps, variables } =
      getState()
    const stepRecord = workflowStepGroupSteps.findById<WorkflowStepRecord>(
      interaction.workflowSteps[0].stepId,
    )
    if (!stepRecord) {
      throw new Error(`Unable to find step record when resetting interaction:
        ${interaction.workflowSteps[0].stepId}`)
    }

    return await updateInteraction(
      (interaction) => {
        const nextInteraction = update(interaction, {
          workflowSteps: { $set: [] },
        })

        return buildInteractionStep(nextInteraction, 0, {
          stepRecord,
          valueHistory: {},
          variables,
          contact: currentContact,
          workflowElements,
        })
      },
      { eventType: 'resetInteraction' },
      true,
    )(dispatch, getState)
  }
}

export function setInteractionStatus(interactionId: ID, status = 0) {
  return (
    dispatch: RelayDispatch<typeof updateInteractionRequest>,
    getState: () => TicketState,
  ) => {
    const interaction =
      getState().ticketInteractions.findById<TicketInteractionRecord>(interactionId)
    if (!interaction) {
      throw new Error(
        `setInteractionStatus: Trying to set status of '${status}' on '${interactionId}'`,
      )
    }

    const nextInteraction = setFinalizeAttributes(interaction, status)
    return updateInteractionRequest(
      embedRecordEvent(nextInteraction, {
        eventType: 'setInteractionStatus',
        value: { status },
      }),
    ).then(relay(dispatch))
  }
}

export function removeInteractionTag(interactionId: ID, tagId: ID) {
  return (
    dispatch: RelayDispatch<typeof updateInteractionRequest>,
    getState: () => TicketState,
  ) => {
    const interaction =
      getState().ticketInteractions.findById<TicketInteractionRecord>(interactionId)
    if (!interaction) {
      throw new Error(
        `removeInteractionTag: failed to remove tag '${tagId}' on '${interactionId}'. Missing interaction.`,
      )
    }
    const nextInteraction = update(interaction, {
      tags: { $set: interaction.tags.filter((t) => t.tagId !== tagId) },
    })

    return updateInteractionRequest(
      embedRecordEvent(nextInteraction, {
        eventType: 'removeInteractionTag',
        value: { tagId },
      }),
    ).then(relay(dispatch))
  }
}

export function addInteractionTag(interactionId: ID, tagId: ID, stepId?: ID) {
  return async (
    dispatch: RelayDispatch<InteractionRelayAction>,
    getState: () => TicketState,
  ) => {
    const interaction =
      getState().ticketInteractions.findById<TicketInteractionRecord>(interactionId)
    if (!interaction) {
      throw new Error(
        `addInteractionTag: failed to add tag '${tagId}' to '${interactionId}'. Missing interaction.`,
      )
    }
    const nextInteraction = {
      ...interaction,
      appliedTags: [...(interaction.appliedTags || []), { tagId, stepId }],
    }

    dispatchInteraction(nextInteraction)(dispatch)
  }
}
