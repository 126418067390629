import React, { useMemo } from 'react'
import styled from 'styled-components'

import ExpirationPill from 'happitu/src/components/ExpirationPill'
import Tag from 'happitu/src/components/Tag'
import getStyle from 'happitu/src/getStyle'
import {
  HelpTopicRecord,
  HelpTopicPageRecord,
  HelpTopicPageStore,
} from 'happitu/src/types/models/helpTopics'
import { font } from 'theme'

interface Props {
  className?: string
  helpTopic: HelpTopicRecord
  onNavigate: (helpTopicId: ID) => void
  showPreview?: boolean
  tags?: TagStore
  helpTopicPages?: HelpTopicPageStore
}

const getTag = (tags?: TagStore, id?: ID | null) => tags && tags.findById<TagRecord>(id)
const PREVIEW_LENGTH = 80
const HelpTopicListItem = (props: Props) => {
  const tag = getTag(props.tags, props.helpTopic.tagId)
  const preview = useMemo(() => {
    if (!props.showPreview || !props.helpTopicPages) return ''
    const firstPage = props.helpTopicPages.findById<HelpTopicPageRecord>(
      props.helpTopic.pageIds[0],
    )
    const subText = firstPage?.searchText.substr(0, PREVIEW_LENGTH)
    return `${subText}${subText && subText.length === PREVIEW_LENGTH ? '...' : ''}`
  }, [props.showPreview])
  return (
    <button
      key={props.helpTopic.id}
      className={props.className}
      onClick={() => props.onNavigate(props.helpTopic.id)}
    >
      <span>
        {props.helpTopic.name}
        {tag && <Tag tag={tag} />}
      </span>

      {props.showPreview && <sub>{preview}</sub>}
      {!!props.helpTopic.expiresAt && (
        <div>
          <ExpirationPill expiresAt={props.helpTopic.expiresAt} />
        </div>
      )}
    </button>
  )
}

export default styled(HelpTopicListItem)`
  background: transparent;
  border-radius: 4px;
  border: 1px solid ${getStyle('border-color')};
  color: ${getStyle('text-default-color')};
  display: block;
  font-size: 1em;
  font-weight: ${font('weight', 'medium')};
  margin-bottom: 1em;
  padding: 1em;
  text-align: left;
  width: 100%;

  sub {
    display: block;
    font-size: ${font('size', 'small')};
    font-weight: ${font('weight', 'normal')};
    overflow: hidden;
    color: ${getStyle('text-sub-color')};
  }
`
