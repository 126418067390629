import React from 'react'
import { Draggable, DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Editor, Element as SlateElement, Node } from 'slate'
import { RenderElementProps, useSlate } from 'slate-react'
import styled, { css } from 'styled-components'
import { ifNotProp, ifProp } from 'styled-tools'

import DisplayAttachment from './DisplayAttachment'
import DisplayHelpTopicAction from './DisplayHelpTopicAction'
import DisplayImage from './DisplayImage'
import EditableAttachment from './EditableAttachment'
import EditableHelpTopicAction from './EditableHelpTopicAction'
import EditableImage from './EditableImage'
import EditableLink from './EditableLink'
import {
  BlockQuote,
  Heading,
  ListItem,
  OrderedList,
  Paragraph,
  Subheading,
  UnorderedList,
} from './Element.styles'
import EditableBlockQuote from './editable-block-quote'
import WorkflowVariable from './editable-workflow-variable'
import ElementDragHandle from './element-drag-handle'
import SectionBreak from './section-break'

import { DragHandle } from 'happitu/src/components/Buttons'
import getStyle from 'happitu/src/getStyle'
import { getUserOption } from 'happitu/src/helpers/user'
import { SlateRecordRefs } from 'happitu/src/types/models/richTextEditor'
import { ElementType } from 'happitu/src/types/slate-types'
import { metric } from 'theme'

interface Props extends RenderElementProps {
  children: React.ReactNode
  dragHandleProps?: DraggableProvidedDragHandleProps
  fileIds?: ID[]
  isDragging?: boolean
  isReadOnly?: boolean
  collections?: SlateRecordRefs
  draggable?: boolean
}

// eslint-disable-next-line complexity
const Element = ({
  attributes,
  children,
  isDragging = false,
  isReadOnly,
  fileIds,
  ...props
}: Props) => {
  const element = props.element
  const interfaceOptions = getUserOption('interfaceOptions')
  const size = interfaceOptions?.fontSize

  switch (element.type) {
    case ElementType.HeadingOne:
      return isReadOnly ? (
        <Heading {...attributes}>{children}</Heading>
      ) : (
        <DragControls dragHandleProps={props.dragHandleProps} isDragging={isDragging}>
          <Heading {...attributes}>{children}</Heading>
        </DragControls>
      )
    case ElementType.HeadingTwo:
      return isReadOnly ? (
        <Subheading {...attributes}>{children}</Subheading>
      ) : (
        <DragControls dragHandleProps={props.dragHandleProps} isDragging={isDragging}>
          <Subheading {...attributes}>{children}</Subheading>
        </DragControls>
      )
    case ElementType.BlockQuote:
      return isReadOnly ? (
        <BlockQuote {...attributes}>{children}</BlockQuote>
      ) : (
        <DragControls dragHandleProps={props.dragHandleProps} isDragging={isDragging}>
          <EditableBlockQuote
            attributes={attributes}
            element={element}
            isDragging={isDragging}
          >
            {children}
          </EditableBlockQuote>
        </DragControls>
      )
    case ElementType.OrderedList:
      return isReadOnly ? (
        <OrderedList
          indentLevel={element.indentLevel}
          start={element.startsAt}
          size={size}
          {...attributes}
        >
          <ListItem>{children}</ListItem>
        </OrderedList>
      ) : (
        <DragControls
          dragHandleProps={props.dragHandleProps}
          indentLevel={element.indentLevel}
          isDragging={isDragging}
        >
          <OrderedList
            indentLevel={element.indentLevel}
            start={element.startsAt}
            {...attributes}
          >
            <ListItem>{children}</ListItem>
          </OrderedList>
        </DragControls>
      )
    case ElementType.UnorderedList:
      return isReadOnly ? (
        <UnorderedList indentLevel={element.indentLevel} {...attributes} size={size}>
          <ListItem>{children}</ListItem>
        </UnorderedList>
      ) : (
        <DragControls
          dragHandleProps={props.dragHandleProps}
          indentLevel={element.indentLevel}
          isDragging={isDragging}
        >
          <UnorderedList indentLevel={element.indentLevel} {...attributes}>
            <ListItem>{children}</ListItem>
          </UnorderedList>
        </DragControls>
      )
    case ElementType.Image:
      return isReadOnly ? (
        <DisplayImage attributes={attributes} fileIds={fileIds} element={element}>
          {children}
        </DisplayImage>
      ) : (
        <EditableImage
          attributes={attributes}
          dragHandleProps={props.dragHandleProps}
          element={element}
          isDragging={isDragging}
        >
          {children}
        </EditableImage>
      )
    case ElementType.Attachment:
      return isReadOnly ? (
        <DisplayAttachment attributes={attributes} element={element}>
          {children}
        </DisplayAttachment>
      ) : (
        <DragControls dragHandleProps={props.dragHandleProps} isDragging={isDragging}>
          <EditableAttachment attributes={attributes} element={element}>
            {children}
          </EditableAttachment>
        </DragControls>
      )
    case ElementType.Link:
      return isReadOnly ? (
        <a href={element.url} rel="noreferrer nofollow noopener" target="_blank">
          {children}
        </a>
      ) : (
        <EditableLink attributes={attributes} element={element}>
          {children}
        </EditableLink>
      )
    case ElementType.SectionBreak:
      return isReadOnly ? (
        <SectionBreak attributes={attributes} element={element}>
          {children}
        </SectionBreak>
      ) : (
        <DragControls dragHandleProps={props.dragHandleProps} isDragging={isDragging}>
          <SectionBreak attributes={attributes} element={element}>
            {children}
          </SectionBreak>
        </DragControls>
      )
    case ElementType.HelpTopicAction:
      return isReadOnly ? (
        <DisplayHelpTopicAction
          attributes={attributes}
          element={element}
          collections={props.collections}
        >
          {children}
        </DisplayHelpTopicAction>
      ) : (
        <EditableHelpTopicAction
          attributes={attributes}
          element={element}
          dragHandleProps={props.dragHandleProps}
          isDragging={isDragging}
        >
          {children}
        </EditableHelpTopicAction>
      )
    case ElementType.WorkflowVariable:
      return (
        <WorkflowVariable attributes={attributes} element={element}>
          {children}
        </WorkflowVariable>
      )
    default:
      return isReadOnly ? (
        <Paragraph size={size} indentLevel={element.indentLevel} {...attributes}>
          {children}
        </Paragraph>
      ) : (
        <DragControls
          dragHandleProps={props.dragHandleProps}
          isDragging={isDragging}
          indentLevel={element.indentLevel}
        >
          <Paragraph indentLevel={element.indentLevel} {...attributes}>
            {children}
          </Paragraph>
        </DragControls>
      )
  }
}

const isInlineElement = (type: ElementType) =>
  [ElementType.Link, ElementType.WorkflowVariable].includes(type)

const DraggableElement = (props: Props) => {
  if (
    props.isReadOnly ||
    isInlineElement(props.element.type) ||
    props.draggable === false
  )
    return <Element {...props} />

  const editor = useSlate()

  // If the node isn't void and doesn't have text, don't allow drag.
  const preventDrag = !Editor.isVoid(editor, props.element) && !Node.string(props.element)

  const blockIndex = editor.children.findIndex(
    (block) => SlateElement.isElement(block) && block.nodeId === props.element.nodeId,
  )

  return (
    <Draggable draggableId={props.element.nodeId} index={blockIndex}>
      {(provided, snapshot) => (
        <DragContainer
          {...provided.draggableProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          preventDrag={preventDrag}
        >
          <Element
            {...props}
            isDragging={snapshot.isDragging}
            dragHandleProps={provided.dragHandleProps}
          />
        </DragContainer>
      )}
    </Draggable>
  )
}

// prettier-ignore
const DragContainer = styled.div<{ isDragging: boolean, preventDrag: boolean }>`
  ${ifNotProp(
    'preventDrag',
    css`
      ${DragHandle} {
        opacity: ${ifNotProp('isDragging', 0)};
        transition: opacity 150ms ease-in-out;
      }

      &:hover {
        ${DragHandle} {
          opacity: 1;
        }
      }
    `, css`
      ${DragHandle} {
        opacity: 0;
        visibility: hidden;
      }
    `
  )}
`

const DragWrapper = styled.div<{ isDragging: boolean }>`
  border-radius: ${metric('borderRadius')};
  position: relative;
  transition: box-shadow 150ms ease-in-out;
  padding: 0 0.5em;

  ${ifProp(
    'isDragging',
    css`
      background: ${getStyle('app-background')};
      box-shadow: ${getStyle('modal-boxShadow')};
    `,
  )}
`

interface DragControlsProps {
  children: React.ReactNode
  dragHandleProps?: DraggableProvidedDragHandleProps
  isDragging: boolean
  indentLevel?: number
}

const DragControls = ({
  // indentLevel = 0,
  ...props
}: DragControlsProps) => {
  return props.dragHandleProps ? (
    <DragWrapper isDragging={props.isDragging}>
      <ElementDragHandle
        dragHandleProps={props.dragHandleProps}
        // style={{
        //   right: `calc(100% + ${dragHandleOffset - indentModifier * indentLevel}em)`,
        // }}
      />

      {props.children}
    </DragWrapper>
  ) : (
    <>{props.children}</>
  )
}
export default DraggableElement
