import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import { Button } from 'happitu/src/components/button'
import getStyle from 'happitu/src/getStyle'
import { color, font } from 'theme'

export const Content = styled.div`
  width: 100%;
  border: 0;
  outline: none;
  background: transparent;
  line-height: 1.5em;
  overflow-wrap: break-word;
  font-size: 16px;
  font-weight: ${font('weight', 'medium')};
  color: ${getStyle('text-default-color')};
  display: block;
  padding: 0;
  z-index: 1;

  b {
    font-weight: 800;
  }

  &:focus {
    box-shadow: none;
  }

  &:placeholder {
    color: ${color('grey', 'lighter')};
  }

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }

  @media screen and (max-width: 1500px) {
    padding-right: 3em;
  }
`

export const FloatingCopyButton = styled(Button)<{ copied: boolean }>`
  position: absolute;
  right: -4em;
  top: 0;
  opacity: 0;
  z-index: 1;

  @media screen and (max-width: 500px) {
    display: none;
  }

  @media screen and (max-width: 1500px) {
    right: 0em;
  }

  ${ifProp(
    'copied',
    css`
      background: ${getStyle('pill-success-background')} !important;
      color: ${getStyle('pill-success-color')} !important;
    `,
  )}
`

export const Wrapper = styled.div<{ isHovering?: boolean }>`
  position: relative;
  display: flex;
  gap: 1em;
  margin: 1em 0;
  margin-left: -2em;

  @media screen and (max-width: 500px) {
    margin-left: 0;
  }

  .mask {
    position: absolute;
    top: -0.75em;
    right: -0.75em;
    bottom: -0.25em;
    left: -0.75em;
    user-select: none;
    border: 2px dashed
      ${ifProp('isHovering', getStyle('app-alt-background'), 'transparent')};
    border-radius: 6px;
  }

  &:before {
    font-family: 'icomoon';
    content: '\\e94d';
    display: inline-block;
    color: ${color('green')};
    margin-top: 0.5em;

    @media screen and (max-width: 500px) {
      margin-top: 0.25em;
    }
  }

  &:hover {
    ${FloatingCopyButton} {
      opacity: 1;
    }
  }
`
