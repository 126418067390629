import React, { useEffect } from 'react'

import { client } from '../../ticketing/views/find-or-create-ticket'

import VoidCallout from 'happitu/src/components/Layout/VoidCallout'
import {
  getParams,
  navigateTo,
  NewTicketRouteParams,
} from 'happitu/src/helpers/routeHelpers'

type Route = NewTicketRouteParams & {
  key?: ID
  id?: string
}

const LookupTicket = () => {
  useEffect(() => {
    const { key: lookupKey, id: lookupValue, ...params } = getParams<Route>()
    if (!lookupKey && !lookupValue) navigateTo('app.portable.new', params as any)
    // @ts-ignore
    client.legacy.lookupTicket
      .query({
        workflowId: params.workflowId,
        key: lookupKey,
        id: lookupValue,
      })
      .then((result: TicketInteractionRecord | undefined) => {
        if (result) {
          navigateTo('app.portable.work', {
            ...params,
            ticketId: result.ticketId,
            ticketInteractionId: result.id,
            stepIndex: `${result.workflowSteps.length - 1}`,
          })
        } else {
          navigateTo('app.portable.new', {
            ...params,
            [`var${lookupKey}`]: lookupValue,
            lookupId: lookupValue,
            lookupKey: lookupKey,
          })
        }
      })
  }, [])

  return <VoidCallout>Initializing ticket...</VoidCallout>
}

export default LookupTicket
